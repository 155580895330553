import React from "react";


const GoogleAds = () =>{
  const GoogleAds = `<img src= "https://images.unsplash.com/photo-1581456495146-65a71b2c8e52?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aHVtYW58ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt="Italian Trulli" height="100%"/>`
  return(
    <>
    <div dangerouslySetInnerHTML={{__html: GoogleAds}} />
    </>
  )
}

export default GoogleAds