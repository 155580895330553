import { Link } from "react-router-dom";

const NotFound = () =>{
  return (
    <main className="flex h-screen w-screen items-center justify-center">
      <div className="container text-center">
        <h2 className="font-bold lg:text-6xl">Nothing Found Here</h2>
        <Link to="/">
        <button className="py-2.5 px-11 rounded mt-10 font-bold" style={{backgroundColor:'#2B88ED'}}>Home</button>
        </Link>
      </div>
    </main>
    
  )
}


export default NotFound;