import React, { useEffect, useState, useRef } from "react";
import LoadingBar from 'react-top-loading-bar';
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Card from "../../components/NewsCard/NewsCard";
import styles from "./News.module.css";


const Category = () => {
  const ref = useRef(null);
  const location = useLocation();
  const PathName = location.pathname.replace('/category/','');
  const[GetCatInfo, SetCatInfo] = useState([]);
  const[GetItems, SetCatItms] = useState([]);
  const[IsFatch, SetFatch] = useState(false);
  let[GetPaged, SetPaged] = useState(1);
  const[loading,SetLoading] = useState(false);
  const[NoMoreData, SetNoMoreData] = useState(false);
  const [NoResultFound, SetNoResultFound] = useState(false);
  const [LoadMoreButtn, ShowLoadMoresButton] = useState(false);
  let [PrevPath,SetPathName] = useState('');
  

  async function HandleCatgory(){
    const CatInfo = await(await fetch(`https://www.sgcarena.com/wp-json/wp/v2/categories/?slug=${PathName}`,{cache: "no-cache"})).json();
    if(CatInfo.length){
      SetCatInfo(CatInfo);
      FeatchItems(CatInfo);
      SetPathName(PathName);
    }else{
      SetNoResultFound(true);
    }
  };

  async function FeatchItems(CatInfo){
    const FatchData = await(await fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts?categories=${CatInfo[0].id}&&per_page=12&&page=${GetPaged}`)).json();
    if(FatchData.length){
      UpdatePageAndLists(FatchData);
      SetFatch(true);
      SetLoading(false);
      ShowLoadMoresButton(true);
      ref.current.complete();
    }else{
      SetLoading(false);
      SetNoMoreData(true);
      ShowLoadMoresButton(false);
    }
  };

  function UpdatePageAndLists(FachecData){
    SetCatItms((PrevData)=>[...PrevData,...FachecData]);
    SetPaged(++GetPaged);
  };

  function HandleScroll(){
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      return;
    }
    if(!NoMoreData){
    SetLoading(true);
    FeatchItems(GetCatInfo);
    }
  };

  function HandleMobileLoadMore(){
    SetLoading(true);
    FeatchItems(GetCatInfo);
  }

  function HandlePathName(){
    if(PrevPath && (PrevPath !== PathName)){
      window.location.reload();
      SetPathName(PathName)
    }
  }
  useEffect(()=>{
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      HandlePathName();
      if(!GetCatInfo.length){
        ref.current.continuousStart();
        HandleCatgory();
      }
    } else if(!GetItems.length){
      if(!GetCatInfo.length){
        ref.current.continuousStart();
        HandleCatgory();
      }
    } else{
      HandlePathName();
      if(IsFatch){
      window.addEventListener('scroll', HandleScroll);
      return () => window.removeEventListener('scroll', HandleScroll);
      }
    }
    
  });
 return(
  <main className={styles.news}>
    <LoadingBar color='#2B88ED' ref={ref} height='3px'/>
    <Header/>
      <div className="main m-auto px-2.5 py-20 container">
        <div className="product-list">
          {<Card items={GetItems}/>}
        </div>
        {LoadMoreButtn &&
        <div className="text-center mt-8 md:hidden lg:hidden">
          <button style={{backgroundColor: '#2B88ED',padding: '8px 18px',borderRadius: '5px'}} onClick={HandleMobileLoadMore}>Load More</button>
        </div>
        }
      </div>
      {NoResultFound &&
        <div>
          <h2 className="text-center font-bold">No Result Found</h2>
        </div>
      }
      {loading &&
      <div className={`loading`}>
        <div className={`lds_spinner`}>
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
      }
      {NoMoreData &&

        <h5 className="text-center text-5xl	pb-16 font-semibold">No More Result</h5>
      }    
    </main>
 )
}

export default Category;