import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import SinglePage from './pages/SinglePage/SinglePage';
import SearchPage from './pages/SearchPage/SearchPage';
import News from "./pages/News/News";
import SingleNews from "./pages/SingleNews/SingleNews";
import NotFound from "./pages/NotFound/NotFound";
import Category from "./pages/Archive/Category/Category";
import GameVersion from "./pages/Archive/GameVersion/GameVersion";
import ModCategory from "./pages/Archive/ModCategory/ModCategory";
import ModType from "./pages/Archive/ModType/ModType";
import Tag from "./pages/Archive/Tag/Tag";

const App =()=>{
  return (
    <Routes>
      <Route path="/" element={<HomePage/>}></Route>
      <Route path="/mod/:slug" element={<SinglePage/>}></Route>
      <Route path="/search/:value" element={<SearchPage/>}></Route>
      <Route path="/mod" element={<Navigate to="/" replace />}/>
      <Route path="*" element={<NotFound/>}/>
      <Route path="/news" element={<News/>}/>
      <Route path="/news/:slug" element ={<SingleNews/>}/>
      <Route path="/category" element={<Navigate to="/" replace />}/>
      <Route path="/category/:slug" element ={<Category/>}/>
      <Route path="/game_version" element={<Navigate to="/" replace />}/>
      <Route path="/game_version/:slug" element ={<GameVersion/>}/>
      <Route path="/mod_category" element={<Navigate to="/" replace />}/>
      <Route path="/mod_category/:slug" element ={<ModCategory/>}/>
      <Route path="/mod_type" element={<Navigate to="/" replace />}/>
      <Route path="/mod_type/:slug" element ={<ModType/>}/>
      <Route path="/tags" element={<Navigate to="/" replace />}/>
      <Route path="/tags/:slug" element ={<Tag/>}/> 
    </Routes>
  );
}

export default App;
