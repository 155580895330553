import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import SearchBox from "../../components/Search/Search";
import Card from "../../components/Cards/Card";
import style from "./HomePage.module.css"
import background from "../../img/Bg.png"

const HomePage = () => {
  const [getItems, SetItems] = useState([]);
  const [IsFatch, GetFatch] = useState(true);
  let [getPage, SetPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [NoResult, SetNoResult] = useState(false);

  async function GetItemsValue() {
    const Fatchdata = await (await fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts/?per_page=12&&page=${getPage}`, { cache: "no-cache" })).json();
    SetPage((preVal) => preVal += 1)
    if (Fatchdata.length) {
      SetItems((PreVal) => [...PreVal, ...Fatchdata])
      setLoading(false);
    } else {
      setLoading(false);
      GetFatch(false)
      SetNoResult(true)
    }

  }

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      return;
    }
    HandleFatch()
  };

  const HandleFatch = () => {
    setLoading(true)
    GetItemsValue()
  }

  useEffect(() => {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      if (!getItems.length) {
        GetItemsValue()
      }
    } else {
      if (IsFatch) {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }
    }

  });


  return (
    <main className={style.home} style={{ backgroundImage: `url(${background})` }}>
      <Header />
      <div className="main m-auto px-2.5 py-24 container">
        <div className="flex lg:flex-row flex-col-reverse items-center gap-5">
          <div className="text-left lg:w-6/12 w-full">
            <h1 className="lg:text-7xl lg:mb-12 font-bold mb-8 text-5xl">Latest Source for BUSSID Mods</h1>
            <p>
              Your source for download the latest BUSSID bus, car, truck, bike, vehicle, map, obb and more mods.
            </p>
            <SearchBox />
          </div>
          <div className="text-right lg:w-6/12 :w-full">
            <img src={require("../../img/sgcarena-banner.png")} alt="sgcarena-banner" />
          </div>
        </div>
        <div className="m-auto lg:mt-24 lg:mb-36 mt-8 mb-12">
          <img className="w-full" src={require("../../img/box.png")} alt="box" />
        </div>
        <div className="product-list">
          <h2 className="text-left font-bold">Latest BUSSID Vehicle Mod</h2>
          <Card items={getItems} />
        </div>
        <div className="text-center mt-8 md:hidden ">
          <button className={`${style.loadmore_btn}`} onClick={HandleFatch}>Load More</button>
        </div>
      </div>
      {loading &&
        <div className={`loading`}>
          <svg class="animate-spin -ml-1 mr-3 h-auto w-10 text-sgca-blue-light" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      }
      {NoResult &&
        <h5 className="text-center text-5xl	pb-16 font-semibold">No More Result</h5>
      }
    </main>
  )
}

export default HomePage;