import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import styles from "./Header.module.css";


const Header = () => {
  const [visible1, setvisible1] = useState(false);
  const [visible2, setvisible2] = useState(false);
  const [menuVisiale, setMenuVisiale] = useState(false);
  const handleClick1 = () => {
    setvisible2(false)
    setvisible1(!visible1)
  }
  const handleClick2 = () => {
    setvisible1(false)
    setvisible2(!visible2)
  }
  const handleMenu = () => {
    setMenuVisiale(!menuVisiale)
  }
  return (
    <header className="md:w-full container m-auto	p-2.5">
      <div className="flex flex-wrap">
        <div className="lg:w-1/4 md:w-3/6 w-2/4">
          <Link to="/" className="md:text-left text-center flex flex-row flex-nowrap relative">
            <img src={require("../../img/sgcarena-logo.png")} alt="sgcarena" />
            <sup className="top-0 leading-none text-sgca-white-D9E6FA">IN (β Version)</sup>
          </Link>
        </div>
        <div className="lg:w-2/4 md:w-1/6 w-2/4 lg:order-none md:order-2 flex lg:justify-center justify-end items-center relative">
          <nav className="desktop-navigation hidden lg:block">
            <ul className="flex">
              <li className={`py-3 px-3.5`}> <Link to="/">Home</Link></li>
              <li className={`py-3 px-3.5`}> <Link to="/news"> News </Link></li>
              <li className={`relative py-3 px-3.5 ${styles.desktop_has_submenu}`} >
                <Link to={`/category/bussid`}>BUSSID MOD</Link>
                <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                <ul className={`absolute bg-white text-black text-left top-12 w-36 text-sm ${styles.desktop_dropdown_list} transition-all`}>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_category/vehicles`}>Vehicles</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_category/maps`}>Maps</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_category/obb`}>Obb</Link></li>
                  <li className="hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to="https://livery.sgcarena.com">BUSSID Livery</Link></li>
                </ul>
              </li>
              <li className={`py-3 px-3.5 relative ${styles.desktop_has_submenu}`} >
                <Link to="#">
                  Mod Type
                </Link>
                <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                <ul className={`absolute bg-white text-black text-left top-12 w-36 text-sm ${styles.desktop_dropdown_list} transition-all`}>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/add-on`}>Add-on</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/bus`}>Bus</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/car`}>Car</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/mountain`}>Mountain</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/off-road`}>Off Road</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/pickup`}>Pickup</Link></li>
                  <li className="border-b hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/replaced`}>Replaced</Link></li>
                  <li className="hover:bg-slate-100"> <Link className="px-4 py-2.5 block" to={`/mod_type/truck`}>Truck</Link></li>
                </ul>
              </li>
            </ul>
          </nav>
          <div onClick={handleMenu} className={`${styles.navbtn} w-10 border-2 border-white h-8 rounded flex flex-wrap items-center lg:hidden`}>
            <div className="h-0.5 w-full bg-white"></div>
            <div className="h-0.5 w-full bg-white"></div>
            <div className="h-0.5 w-full bg-white"></div>
          </div>
          {menuVisiale &&
            <nav className={`${styles.mobile_navigation} fixed min-h-screen w-screen z-10`}>
              <div className="text-left">
                <FontAwesomeIcon icon={faXmark} className="text-white p-4" onClick={handleMenu} />
              </div>
              <ul className="text-left">
                <li className={`py-3 px-3.5`}> <Link to="/">Home</Link></li>
                <li className={`py-3 px-3.5`}> <Link to="/news"> News </Link></li>
                <li className={`py-3 px-3.5`} onClick={handleClick1}>
                  <Link to={`/category/bussid`}>BUSSID MOD</Link>
                  <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                  {visible1 &&
                    <ul className={`text-left top-12 w-36 text-sm transition-all`}>
                      <li className="p-4"> <Link to="/mod_category/vehicles"> Vehicles </Link></li>
                      <li className="p-4"> <Link to="/mod_category/maps"> Maps </Link></li>
                      <li className="p-4"> <Link to="/mod_category/obb"> Obb </Link></li>
                      <li className="p-4"> <Link to="https://livery.sgcarena.com"> BUSSID Livery </Link></li>
                    </ul>
                  }
                </li>
                <li className={`py-3 px-3.5`} onClick={handleClick2}>
                  <Link to="#">
                    Mod Type
                  </Link>
                  <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                  {visible2 &&
                    <ul className={`text-left top-12 w-36 text-sm} transition-all`}>
                      <li className="p-4"> <Link to="/mod_type/add-on">Add-on</Link></li>
                      <li className="p-4"> <Link to="/mod_type/bus">Bus</Link></li>
                      <li className="p-4"> <Link to="/mod_type/car">Car</Link></li>
                      <li className="p-4"> <Link to="/mod_type/mountain">Mountain</Link></li>
                      <li className="p-4"> <Link to="/mod_type/off-road">Off Road</Link></li>
                      <li className="p-4"> <Link to="/mod_type/pickup">Pickup</Link></li>
                      <li className="p-4"> <Link to="/mod_type/replaced">Replaced</Link></li>
                      <li className="p-4"> <Link to="/mod_type/truck">Truck</Link></li>
                    </ul>
                  }
                </li>
              </ul>
            </nav>
          }
        </div>
        <div className="lg:w-1/4 md:w-2/6 lg:flex md:flex justify-end items-center hidden">
          <div className="header_right_btn">
            <Link className="singup_btn mx-2 font-bold" to="/">Sing up</Link>
            <Link className={`mx-2 font-bold rounded-lg px-5 py-2.5`} style={{ backgroundColor: '#2B88ED' }} to="/">Login</Link>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header;