import React, {useState} from "react";
import styles from "./ModFile.module.css";
import GoogleAdsCard from "../GoogleAdsCard/GoogleAdsCard";

const ModInformation=(props)=>{
 
  const item = props.item;
  const[ShowAds, SetAds] = useState(false);
  function HandleAds(index){
    SetAds(index);
  }
  return(
    <>
    {item &&  (
      <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-x-3.5">
        {item.acf.mod_variant_repeater.map((mod_variant, index)=>{
          return(
            <div className={`${styles.table} p-5 mt-9 rounded-md relative`} key={index}>
              {(ShowAds===index) && (
                <>
                  <GoogleAdsCard mod_zip_file={
                    {
                      reference: !(mod_variant.mod_variant_file_repeater.file === "")? mod_variant.mod_variant_file_repeater.file : mod_variant.mod_variant_link_repeater,
                      type: !(mod_variant.mod_variant_file_repeater.file === "")? "token" : "url",
                    }
                    }/>
                </>
              )}
              <div className="mod_file_img mb-2">
                {item.acf.mod_variant_image_repeater?(
                  <img src={item.acf.mod_variant_image_repeater} alt={item.acf.mod_name}/>
                ):(
                  <img src={item.jetpack_featured_media_url} alt={item.acf.mod_name}/>
                )}
              </div>
              <table className={`w-full`}>
                <tbody>
                  {item.acf.mod_name && (
                  <tr>
                    <td>Model</td>
                    <td>{item.acf.mod_variant_repeater[0].mod_variant_name_repeater}</td>
                  </tr>
                  )}
                  {item.acf.mod_size && (
                  <tr>
                    <td>Model</td>
                    <td>{item.acf.mod_size} MB</td>
                  </tr>
                  )}
                  {item.acf.mod_author && (
                  <tr>
                    <td>Author</td>
                    <td>{item.acf.mod_author}</td>
                  </tr>
                  )}
                  <tr>
                    <td>Copyright</td>
                    <td>SGCArena</td>
                  </tr>
                </tbody>
              </table>
              <div className="w-full flex justify-center">
                <button className="w-full py-3 rounded-full text-white font-bold my-4" style={{backgroundColor: '#253D63'}} onClick={()=>{HandleAds(index)}}>Download</button>
              </div>
            </div>
          )
        })}
      </div>
    )}
    </>
  )
}

export default ModInformation;