import React, {useState, useEffect, useRef, useCallback} from "react";
import urlSlug from 'url-slug';
import {Link, useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import ImageViewer from 'react-simple-image-viewer';
import ScrollContainer from 'react-indiana-drag-scroll';
import ModInfo from '../../components/ModInformation/ModInfo';
import ModFile from "../../components/ModFile/ModFile";
import styles from "./SinglePage.module.css";
import Header from "../../components/Header/Header";
import SingleCard from "../../components/SingleCard/SingleCard";

const SinglePage = () => {  
  const ref = useRef(null);
  let params = useParams();
  let slugUrl = params.slug;
  const images = [];
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loader, setLoading] = useState(true);
  const [SinglePost, SetSinglePost] = useState([]);
  const [RelatedPost, SetRelatedPost] = useState();
  const [NoResult, SetNoResult] = useState(false);
  // const [GetGalleyInfo, SetGalleryInfo] = useState([]);
  async function FatchSinglePost(){
    const [FatchData_one, FatchData_two] = await Promise.all([
      fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts/?slug=${slugUrl}`),
      fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts/?per_page=3&&page=1`)
    ])
    const SinglePostData = await FatchData_one.json();
    const RelatedPostData = await FatchData_two.json();

    if(RelatedPostData.length){
      SetRelatedPost(RelatedPostData);
    }

    if(SinglePostData.length){
      SetSinglePost(SinglePostData);
      setLoading(false);
      ref.current.complete();
    }else{
      SetNoResult(true);
      setLoading(false)
    }
  };
  const [ShowModInfo, SetModInfo] = useState(true);
  const HandleModInfo=()=>{
    SetModFile(false);
    SetModInfo(true);
  };

  const [ShowModFile, SetModFile] = useState(false);
  const HandleModFile=()=>{
    SetModInfo(false);
    SetModFile(true);

  };
  const Handlescroll=()=>{
    const section = document.querySelector( '#ModInformation' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    SetModInfo(false);
    SetModFile(true);
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  let HandleImages =(url)=>{
    let Url = `${url}`;
    images.push(Url);
  }
  useEffect(()=>{
    if(!SinglePost.length){
      ref.current.continuousStart();
      window.scrollTo({top: 0, left: 0});
      FatchSinglePost();
    }
  });

  return(
    <main className={`${styles.single}`}>
      <LoadingBar color='#2B88ED' ref={ref} height='3px'/>
      <Header/>
      {(!NoResult && SinglePost[0])&& (
        <section>
          <section className="pt-16 container m-auto p-2.5">
            <h1 className="text-4xl font-bold">{SinglePost[0] && SinglePost[0].acf.mod_name}</h1>
            <div className="lg:flex lg:flex-wrap mt-12 lg:flex-row flex-col">
              <div className="lg:w-8/12 lg:pr-2.5">
                {SinglePost[0] && (
                <>
                {HandleImages(SinglePost[0] && SinglePost[0].jetpack_featured_media_url)}
                <img className={`${styles.single_img}`} onClick={()=>openImageViewer(0)} src={SinglePost[0] && SinglePost[0].jetpack_featured_media_url} height="500px" alt={SinglePost[0] && SinglePost[0].acf.mod_name}/>
                </>
                )}
                {SinglePost[0].acf.mod_gallery &&(
                <ScrollContainer className="scroll-container flex mt-4 space-x-3 overflow-x-scroll scrollbar-hide">
                  {SinglePost[0].acf.mod_gallery.map((item,index)=>{
                    return(
                      <div className={`${styles.gallery_item}`} key={index}>                  
                        {HandleImages(item.link)}
                        <img onClick={()=>openImageViewer(index+1)} src={item.link} alt={SinglePost[0] && SinglePost[0].acf.mod_name}/>
                      </div>
                    );
                  })}
                </ScrollContainer>
                  )}
                {isViewerOpen && (
                  <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                  />
                )}
              </div>
              <div className="lg:w-4/12 lg:pl-2.5 lg:mt-0 mt-5">
                <div className={`${styles.singleCard} p-6 flex flex-wrap flex-col justify-between w-full	`}>
                  <ul className="header">
                    <li className="flex leading-7 mb-2"><b>Name:</b><span className="ml-1">{SinglePost[0] && SinglePost[0].acf.mod_name}</span></li>
                    <li className="flex leading-7 mb-2"><b>Game:</b><span className="ml-1">Bus Simulator Indonesia</span></li>
                    <li className="flex leading-7 mb-2"><b>Author:</b><span className="ml-1">{SinglePost[0] && SinglePost[0].acf.mod_author}</span></li>
                    <li className="flex leading-7 mb-2"><b>Credit:</b><span className="ml-1"> {SinglePost[0] && SinglePost[0].acf.mod_credit} </span></li>
                    <li className="flex leading-7 mb-2"><b>Price:</b><span className="ml-1"> {SinglePost[0] && SinglePost[0].acf.mod_status}</span></li>
                    <li className="flex leading-7 mb-2"><b>Size:</b><span className="ml-1"> {SinglePost[0] && SinglePost[0].acf.mod_size} MB </span></li>
                  </ul>
                  <div className={`${styles.footer} w-full`}>
                      <button className="w-full py-3 rounded-full text-white font-bold mb-5" onClick={Handlescroll}>Download</button>
                    {SinglePost[0]  &&(
                    <ScrollContainer className="scroll-container flex mt-4 max-w-xl space-x-3 overflow-x-scroll scrollbar-hide">
                      {SinglePost[0].category_info && SinglePost[0].category_info.map(function(cat, index){
                        return (
                          <Link key={index} className="w-fit" to={`/category/${cat.slug}`}>
                            <p key={cat.value} className={`${styles.cat_list}`}>{cat.name}</p>
                          </Link>
                        )
                      })}
                      {SinglePost[0].tag_info && SinglePost[0].tag_info.map(function(post_tag,index){
                        return (
                        <Link key={index} className="tag" to={`/tags/${post_tag.slug}`}>  
                          <p className={`${styles.cat_list}`}>{post_tag.name}</p>
                        </Link>
                        )
                      })}
                      {SinglePost[0].taxonomy_info.game_version && SinglePost[0].taxonomy_info.game_version.map(function(game, index){
                        return (
                        <Link key={index} className="w-fit" to={`/game_version/${urlSlug(game.label)}`}> 
                          <p key={game.value} className={`${styles.cat_list}`}>{game.label}</p>
                        </Link>
                        )
                      })}
                      {SinglePost[0].taxonomy_info.mod_category && SinglePost[0].taxonomy_info.mod_category.map(function(mod_cat, index){
                        return (
                          <Link key={index} className="w-fit" to={`/mod_category/${urlSlug(mod_cat.label)}`}>
                            <p key={mod_cat.value} className={`${styles.cat_list}`}>{mod_cat.label}</p>
                          </Link>
                        )
                      })}
                      {SinglePost[0].taxonomy_info.mod_type && SinglePost[0].taxonomy_info.mod_type.map(function(mod_typ, index){
                        return (
                        <Link key={index} className="w-fit" to={`/mod_type/${urlSlug(mod_typ.label)}`}> 
                          <p key={mod_typ.value} className={`${styles.cat_list}`}>{mod_typ.label}</p>
                        </Link> 
                        )
                      })}
                    </ScrollContainer>  
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-16 container m-auto flex lg:flex-row flex-col p-2.5">
            <div className={`basis-10/12 lg:pr-2.5`}>
              <div className={`${styles.tab_title} flex text-sm font-medium`}>
                <span className={`mr-1.5 py-1.5 px-3 rounded-3xl cursor-pointer ${ShowModInfo? styles.active:""}`}onClick={HandleModInfo}>Mod Information</span>
                <span className={`ml-1.5 py-1.5 px-3 rounded-3xl cursor-pointer ${ShowModFile? styles.active:""}`} onClick={HandleModFile}>Mod File</span>
              </div>
              <div id="ModInformation">
                {(ShowModInfo) && (<ModInfo item={SinglePost[0]}/>)}
                {(ShowModFile) && (<ModFile item={SinglePost[0]}/>)}
              </div>
            </div>
            <div className="basis-2/12 lg:pl-2.5 lg:block flex justify-between flex-wrap mt-14 pt-10 border-t-2 border-white md:mt-0 md:pt-0 md:border-t-0">
            {RelatedPost && (
              <SingleCard RelatedPosts={RelatedPost} />
            )}            
              
            </div> 
          </section>
        </section>
      )}
      {NoResult && (
        <div className={`${styles.NoResult}`}>
          <h2 className="text-center font-bold">No Result Found</h2>
        </div>
      )}
      {loader && (
      
      <div className={`loading`}>
        <div className={`lds_spinner`}>
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
      )}
    </main>
  )
}

export default SinglePage;