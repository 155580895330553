import React from "react"
import ScrollContainer from 'react-indiana-drag-scroll'
import { Link } from "react-router-dom";
import urlSlug from 'url-slug';
import style from "./Cards.module.css";
import "./CardCarosel.css";


const Card = (props) => {
  var items = props.items;
  return (
    <div className={`${style.cards} grid lg:grid-cols-4 md:grid-cols-3`}>
      {items.length > 0 &&
        items.map(function (item, index) {
          return (
            <div className={`${style.card} p-3 pb-4 rounded-[10px] bg-sgca-blue-dark-hover`} key={index}>
              <Link to={`/mod/${item.slug}`}>
                <img className={`${style.featured_img} w-full`} src={item.featured_image_src_large[0]} alt={item.acf.mod_name} />
              </Link>
              <div className="mt-3">
                <Link to={`/mod/${item.slug}`}>
                  <h3 className="font-bold text-sgca-white-FFFFFF/[0.8] truncate">{item.acf.mod_name ? item.acf.mod_name : item.title.rendered}</h3>
                </Link>
                <Link to={`/mod/${item.slug}`}>
                  <div className={`${style.card_meta_info} flex justify-between mt-1`}>
                    <p className="text-sm text-sgca-white-FFFFFF/[0.8]"><span className="text-sgca-blue-light/[0.6]">by</span> {item.acf.mod_author ? item.acf.mod_author : item.author_info.display_name}</p>
                    <div className="flex items-center text-sgca-blue-light/[0.6]">
                      <div className="flex items-center mx-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-auto text-sgca-blue-light/[0.6]">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                        </svg>

                        <span className="download-count text-sm ml-0.5">---</span>
                      </div>
                      <div className="flex items-center mx-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-auto">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                        </svg>

                        <span className="like-count text-sm ml-0.5">---</span>
                      </div>
                    </div>
                  </div>
                </Link>
                <ScrollContainer className="scroll-container flex flex-nowrap gap-2 border-t border-sgca-blue-dark mt-3 pt-3 max-w-xl space-x-3 overflow-x-scroll scrollbar-hide">
                  {item.category_info && item.category_info.map(function (cat, index) {
                    return (
                      <Link key={index} to={`/category/${cat.slug}`}>
                        <span className={`rounded-2xl py-2 px-5 bg-sgca-blue-dark text-xs text-sgca-blue-light inline-block cursor-pointer capitalize min-w-max`}>{cat.name}</span>
                      </Link>)
                  })}
                  {item.tag_info && item.tag_info.map(function (post_tag, index) {
                    return (
                      <Link key={index} to={`/tags/${post_tag.slug}`}>
                        <span className={`rounded-2xl py-2 px-5 bg-sgca-blue-dark text-xs text-sgca-blue-light inline-block cursor-pointer capitalize min-w-max`}>{post_tag.name}</span>
                      </Link>
                    )
                  })}
                  {item.taxonomy_info.game_version && item.taxonomy_info.game_version.map(function (game, index) {
                    return (
                      <Link key={index} to={`/game_version/${urlSlug(game.label)}`}>
                        <span className={`rounded-2xl py-2 px-5 bg-sgca-blue-dark text-xs text-sgca-blue-light inline-block cursor-pointer capitalize min-w-max`}>{game.label}</span>
                      </Link>
                    )
                  })}
                  {item.taxonomy_info.mod_category && item.taxonomy_info.mod_category.map(function (mod_cat, index) {
                    return (
                      <Link key={index} to={`/mod_category/${urlSlug(mod_cat.label)}`}>
                        <span className={`rounded-2xl py-2 px-5 bg-sgca-blue-dark text-xs text-sgca-blue-light inline-block cursor-pointer capitalize min-w-max`}>{mod_cat.label}</span>
                      </Link>)
                  })}
                  {item.taxonomy_info.mod_type && item.taxonomy_info.mod_type.map(function (mod_typ, index) {
                    return (
                      <Link key={index} to={`/mod_type/${urlSlug(mod_typ.label)}`}>
                        <span key={mod_typ.value} className={`rounded-2xl py-2 px-5 bg-sgca-blue-dark text-xs text-sgca-blue-light inline-block cursor-pointer capitalize min-w-max`}>{mod_typ.label}</span>
                      </Link>
                    )
                  })}
                </ScrollContainer>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Card;