import React from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
import urlSlug from 'url-slug';
import { Link } from "react-router-dom";
import style from "./Cards.module.css";
import "./CardCarosel.css";


const Card = (props) => {

  var items = props.items;

  return(
    <div className={`${style.cards} grid lg:grid-cols-4 md:grid-cols-3`}>
      { items.length > 0 && 
      items.map(function(item, index){
        return(
          <div className={`${style.card}`} key={index}>
            <Link to={`/news/${item.slug}`}>
            <img className={`${style.featured_img} w-full`} src={item.featured_image_src_large[0]} alt={item.acf.mod_name} />
            </Link>
            <div className="mt-4">
              <Link to={`/news/${item.slug}`}>
              <h3 className="font-bold">{item.acf.mod_name? item.acf.mod_name : item.title.rendered}</h3>
              </Link>
              <Link to={`/news/${item.slug}`}>
              <div className={`${style.card_meta_info} flex justify-between mt-1`}>
                <p className="text-sm"><span style={{color:"#afcaf499"}}>by</span> {item.acf.mod_author ? item.acf.mod_author : item.author_info.display_name}</p>
                <div className="flex items-center">
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/download-cloud.png")} alt="download-icon"/>
                    <span className="download-count text-sm ml-0.5">241</span>
                  </div>
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/thumbs-up.png")} alt="thumbsup-icon"/>
                    <span className="like-count text-sm ml-0.5">241</span>
                  </div>
                </div>
              </div>
              </Link>
              <ScrollContainer className="scroll-container flex border-t mt-4 pt-2 max-w-xl space-x-3 overflow-x-scroll scrollbar-hide">
                {item.category_info && item.category_info.map(function(cat, index){
                  return (
                  <Link key={index} to={`/category/${cat.slug}`}>
                    <p className={`${style.cat_list}`}>{cat.name}</p>
                  </Link>
                  )
                })}
                {item.tag_info && item.tag_info.map(function(post_tag,index){
                  return (
                  <Link key={index} to={`/tags/${post_tag.slug}`}>  
                    <p className={`${style.cat_list}`}>{post_tag.name}</p>
                  </Link>
                  )
                })}
                {item.taxonomy_info.game_version && item.taxonomy_info.game_version.map(function(game, index){
                  return (
                  <Link key={index} to={`/game_version/${urlSlug(game.label)}`}>  
                    <p className={`${style.cat_list}`}>{game.label}</p>
                  </Link>
                  )
                })}
                {item.taxonomy_info.mod_category && item.taxonomy_info.mod_category.map(function(mod_cat, index){
                  return (
                  <Link key={index} to={`/mod_category/${urlSlug(mod_cat.label)}`}> 
                    <p className={`${style.cat_list}`}>{mod_cat.label}</p>
                  </Link>
                  )
                })}
                {item.taxonomy_info.mod_type && item.taxonomy_info.mod_type.map(function(mod_typ, index){
                  return (
                  <Link key={index} to={`/mod_type/${urlSlug(mod_typ.label)}`}>   
                  <p className={`${style.cat_list}`}>{mod_typ.label}</p>
                  </Link>
                  )
                })}
              </ScrollContainer>
            </div>
            
          </div>
       )
      })
      }    
    </div>
  )
}

export default Card;