import React, {useState, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import styles from "./SingleNews.module.css";
import Header from "../../components/Header/Header";
import SingleCard from "../../components/SingleCard/SingleCard";

const SinglePage = () => {
  const ref = useRef(null);
  let params = useParams();
  let slugUrl = params.slug;
  const [loader, setLoading] = useState(true)
  const [SinglePost, SetSinglePost] = useState([]);
  const [RelatedPost, SetRelatedPost] = useState();
  const [NoResult, SetNoResult] = useState(false);
  async function FatchSinglePost(){
    const [FatchData_one, FatchData_two] = await Promise.all([
      fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts/?slug=${slugUrl}`),
      fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts/?per_page=5&&page=1`)
    ])
    const SinglePostData = await FatchData_one.json();
    const RelatedPostData = await FatchData_two.json();

    if(RelatedPostData.length){
      SetRelatedPost(RelatedPostData);
    }

    if(SinglePostData.length){
      SetSinglePost(SinglePostData); 
      setLoading(false);
      ref.current.complete();
    }else{
      SetNoResult(true);
      setLoading(false)
    }
  }
  useEffect(()=>{
    if(!SinglePost.length){
      ref.current.continuousStart();
      window.scrollTo({top: 0, left: 0});
      FatchSinglePost();
    }
  })

  return(
    <main className={`${styles.single}`}>
      <LoadingBar color='#2B88ED' ref={ref} height='3px' />
      <Header/>
      {(!NoResult && SinglePost[0])&& (
        <section>
          <section className="py-16 container m-auto flex lg:flex-row flex-col p-2.5">
            <div className={`basis-10/12 lg:pr-2.5 ${styles.content}`}>
              <h1 className="text-4xl font-bold">{SinglePost[0].title.rendered}</h1>
              <img className="pt-8" src={SinglePost[0].jetpack_featured_media_url} alt={SinglePost[0].title.rendered} width="100%"/>
              <div dangerouslySetInnerHTML={{__html: SinglePost[0].content.rendered}} />
            </div>
            <div className="basis-2/12 lg:pl-2.5 lg:block flex justify-between flex-wrap">
            {RelatedPost && (
              <SingleCard RelatedPosts={RelatedPost} />
            )}            
              
            </div> 
          </section>
        </section>
      )}
      {NoResult && (
        <div className={`${styles.NoResult}`}>
          <h2 className="text-center font-bold">No Result Found</h2>
        </div>
      )}
      {loader && (
      
      <div className={`loading`}>
        <div className={`lds_spinner`}>
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
      )}
    </main>
  )
}

export default SinglePage;