import React, {useState} from "react";
import styles from "./ModLinfo.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import {Link, useParams} from "react-router-dom";

const ModInformation=(props)=>{
  const item = props.item;
  const [ShowText, HideTxt] = useState(false);
  const copyPassword = (getPass) =>{
    navigator.clipboard.writeText(getPass);
    HideTxt(true);
    setTimeout(()=>{
      HideTxt(false);
    },500)
  }
  return(
    <>
    {item && (
      <div className={`${styles.table} p-5 mt-9 rounded-md`}>
        <table className={`w-full`}>
          <tbody>
            {item.acf.mod_name && (
            <tr>
              <td>Model</td>
              <td>{item.acf.mod_name}</td>
            </tr>
            )}

            {item.acf.mod_author && (
            <tr>
              <td>Author</td>
              <td>{item.acf.mod_author}</td>
            </tr>
            )}
            
            {item.acf.mod_credit && (
            <tr>
              <td>Credit</td>
              <td>{item.acf.mod_credit}</td>
            </tr>
            )}

            {item.title.rendered && (
            <tr>
              <td>Game</td>
              <td><Link className="w-fit" to={`/category/bussid`}>BUSSID</Link></td>
            </tr>
            )}

            {item.taxonomy_info.game_version && (
            <tr>
              <td>Compatible</td>
              <td>
                {item.taxonomy_info.game_version.map(function(varsion){
                  return (<div key={varsion.value}>{varsion.label}</div>)
                })}
              </td>
            </tr>
            )}
            {item.taxonomy_info.mod_category && (
            <tr>
              <td>Category</td>
              <td>
                {item.taxonomy_info.mod_category.map(function(mod_cat){
                  return (<div key={mod_cat.value}>{mod_cat.label}</div>)
                })}
              </td>
            </tr>
            )}
            {item.taxonomy_info.mod_type && (
            <tr>
              <td>Type</td>
              <td>
                {item.taxonomy_info.mod_type.map(function(mod_typ){
                  return (<div key={mod_typ.value}>{mod_typ.label}</div>)
                })}
              </td>
            </tr>
            )}
            {item.acf.mod_status && (
            <tr>
              <td>Status</td>
              <td>
                {item.acf.mod_status}
              </td>
            </tr>
            )}
            {item.acf.mod_size && (
            <tr>
              <td>File Size</td>
              <td>
                {item.acf.mod_size} MB
              </td>
            </tr>
            )}
            {item.acf.mod_type &&(
            <tr>
              <td>File Type</td>
              <td>
                { item.acf.mod_type}
              </td>
            </tr>
            )}
            {item.acf.mod_password && (
            <tr>
              <td>Password</td>
              <td className="relative">
                {item.acf.mod_password} 
                <FontAwesomeIcon className="float-right cursor-pointer" icon={faClipboard} onClick={() => copyPassword(item.acf.mod_password)} /> 
                {ShowText && <span className={`${styles.copid_txt} absolute top-5`}>Copid!</span>}
              </td>
            </tr>
            )}
            {item.acf.custom_mod_information && 
              item.acf.custom_mod_information.map(function(CmodInfo, index){
                return(
                <tr key={index}>
                  <td>{CmodInfo.title_custom_mod_information}</td>
                  <td>{CmodInfo.value_custom_mod_information}</td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )}
    </>
  )
}

export default ModInformation;