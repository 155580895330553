import React,{useState, useEffect} from "react";
// import {Link} from "react-router-dom";
import GoogleAds from "../GoogleAds/GoogleAds";
import "./GoogleAdsLoading.css";

const GoogleAdsCard = (props) =>{
  const [ShowLoading, SetLoading] = useState(true);
  const [GenerateLoad, SetGenerateLoad] = useState(false);
  const [Generatting, SetGeneratting] = useState(false);
  const [GetDownload, SetDownload] = useState(false);
  const [GetToken, SetToken] = useState([]);
  const [fetchToken, setFeatchToken] = useState(true)
  async function handle_fatch_token(){
    if(fetchToken){
      setFeatchToken(false);  
      
      if(props.mod_zip_file.type === "token"){
        const response = await fetch(`https://api.sgcarena.com/v1/file/${props.mod_zip_file.reference}`);
        const data= await response.json();

        const responded = await fetch(`https://api.sgcarena.com/v1/download/${data.data.token}`);
        const respondedData = await responded.json();
        SetToken(respondedData.data.download_url);
      } else{
        SetToken(props.mod_zip_file.reference);
      }
    }
  }

  function download_fetch_data(){
    // window.location.replace = GetToken
    window.open(GetToken, '_blank');
    console.log(GetToken);
  }

  useEffect(()=>{
    if(ShowLoading){
      handle_fatch_token()
      setTimeout(()=>{
        SetLoading(false);
        SetGenerateLoad(true)
      },2000);

    } else if(GenerateLoad){
      setTimeout(()=>{
        SetGenerateLoad(false);
        SetGeneratting(true);
      },4000);
    } else if(Generatting){
      setTimeout(()=>{
        SetGeneratting(false);
        SetDownload(true);
      },2000);
    }
  })
  return(
    <div className="GoogleAdsCard absolute h-full w-full top-0 left-0 rounded p-5 text-center backdrop-blur">
      <div className="h-4/5 overflow-hidden">
        <GoogleAds/>
      </div>
      {GetDownload ?
      (<button className="blue-btn rounded-full mx-auto mt-10 font-bold inline-block w-full" onClick={download_fetch_data}>
          Download
      </button>)
      :
      (<button className="blue-btn rounded-full mx-auto mt-10 font-bold inline-block w-full">
        {(ShowLoading) && (
        <svg className="animate-spin ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        )}
        {GenerateLoad &&(
          <p>Generating Link...</p>
        )}
        {Generatting &&(
          <>Getting Link...</>
        )}
      </button>)}
    </div>
  )
}

export default GoogleAdsCard;
