import React from "react";
import urlSlug from 'url-slug';
import {Link, useNavigate} from "react-router-dom";
import ScrollContainer from 'react-indiana-drag-scroll';
import style from "./SingleCard.module.css";

const SingleComponent = (props, ref) =>{
  const RelatedPosts = props.RelatedPosts;
  let navigate = useNavigate();
  const OnHandleLink = (slug) =>{
    navigate(`/mod/${slug}`)
    window.location.reload();
  }
  return(
    <>
      {RelatedPosts.length &&(
        RelatedPosts.map((RelatedPost)=>{
          return(
            <div className={`${style.card} mt-15`} key={RelatedPost.id}>
              <img className="w-full cursor-pointer" src={RelatedPost.jetpack_featured_media_url} alt="demo-img" onClick={()=>OnHandleLink(RelatedPost.slug)}/>
              <div className="mt-4">
                <div className="cursor-pointer" onClick={()=>OnHandleLink(RelatedPost.slug)}>
                  <h3 className="font-bold">{RelatedPost.acf.mod_name}</h3>
                  <div className={`${style.card_meta_info} flex justify-between mt-0.5`}>
                    <p className="text-sm"><span style={{color:"#afcaf499"}}>by </span>{RelatedPost.acf.mod_author}</p>
                    <div className="flex items-center">
                      <div className="flex items-center mx-0.5">
                        <img src={require("../../img/download-cloud.png")} alt="download-icon"/>
                        <span className="download-count text-sm ml-0.5">---</span>
                      </div>
                      <div className="flex items-center mx-0.5">
                        <img src={require("../../img/thumbs-up.png")} alt="thumbsup-icon"/>
                        <span className="like-count text-sm ml-0.5">---</span>
                      </div>
                    </div>
                  </div>
                </div>
                 {RelatedPost  &&(
                <ScrollContainer className={`${style.cat_info} scroll-container border-t border-slate-800 mt-2 pt-2 flex mt-4 max-w-xl space-x-3 overflow-x-scroll scrollbar-hide`}>
                  {RelatedPost.category_info && RelatedPost.category_info.map(function(cat, index){
                    return (
                      <Link key={index} className="w-fit" to={`/category/${cat.slug}`}>
                        <p key={cat.value} className={`${style.cat_list}`}>{cat.name}</p>
                      </Link>
                    )
                  })}
                  {RelatedPost.tag_info && RelatedPost.tag_info.map(function(post_tag,index){
                    return (
                    <Link key={index} className="tag" to={`/tags/${post_tag.slug}`}>  
                      <p className={`${style.cat_list}`}>{post_tag.name}</p>
                    </Link>
                    )
                  })}
                  {RelatedPost.taxonomy_info.game_version && RelatedPost.taxonomy_info.game_version.map(function(game, index){
                    return (
                    <Link key={index} className="w-fit" to={`/game_version/${urlSlug(game.label)}`}> 
                      <p key={game.value} className={`${style.cat_list}`}>{game.label}</p>
                    </Link>
                    )
                  })}
                  {RelatedPost.taxonomy_info.mod_category && RelatedPost.taxonomy_info.mod_category.map(function(mod_cat, index){
                    return (
                      <Link key={index} className="w-fit" to={`/mod_category/${urlSlug(mod_cat.label)}`}>
                        <p key={mod_cat.value} className={`${style.cat_list}`}>{mod_cat.label}</p>
                      </Link>
                    )
                  })}
                  {RelatedPost.taxonomy_info.mod_type && RelatedPost.taxonomy_info.mod_type.map(function(mod_typ, index){
                    return (
                    <Link key={index} className="w-fit" to={`/mod_type/${urlSlug(mod_typ.label)}`}>  
                      <p key={mod_typ.value} className={`${style.cat_list}`}>{mod_typ.label}</p>
                    </Link>
                    )
                  })}
                </ScrollContainer>
                )}
              </div>
              
            </div>
          )
        })
      )
      }
    </>
  )
}



export default SingleComponent;