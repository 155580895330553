import React, { useEffect, useState, useRef } from "react";
import LoadingBar from 'react-top-loading-bar';
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Card from "../../components/Cards/Card";


const SearchComponent = () => {
  const ref = useRef(null);
  const [GetData, setData] = useState([]);
  let [GetPaged, SetPaged] = useState(1);
  const params= useParams();
  const ParamsValue = params.value;
  const [PararalFatch, SetPararalFatech] = useState(false);
  const [loading, SetLoading] = useState(true);
  const [NoMoreData, SetNoMoreData] =useState(false);
  const [NoResultFound, SetNoResultFound] = useState(false);
  const [LoadMore, SetLoadMore] = useState(true);
  const [Mobilefatch, SetMobileFatch] = useState(true);

  async function getFatchData () {
    const data = await(await fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts/?search=${ParamsValue}&&all&&per_page=12&&page=${GetPaged}`)).json();
    HandleFatchData(data);
    SetLoading(false);
    SetMobileFatch(false);
  }

  function HandleMobileLoadMore(){
    SetLoading(true);
    getFatchData();
  }

  function HandleFatchData(data){
    if(data.length){
      setData((PrevData)=> [...PrevData,...data]);
      SetPaged(++GetPaged);
      ref.current.complete();
      SetPararalFatech(true);
    } else{
      SetLoading(false);
      SetLoadMore(false);
      if(GetData.length){
        SetNoMoreData(true);
        SetPararalFatech(false);
      } else{
        ref.current.complete();
        SetNoResultFound(true);

      }
    }
  }
  function HandleScroll(){
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      return;
    }
    SetLoading(true);
    getFatchData();
  };
  useEffect(()=>{
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      if(Mobilefatch){
        ref.current.continuousStart();
        SetLoading(true);
        getFatchData();
      }
    } else if(!GetData.length){
        ref.current.continuousStart();
        getFatchData();
    } else{
      if(PararalFatch){
      window.addEventListener('scroll', HandleScroll);
      return () => window.removeEventListener('scroll', HandleScroll);
      }
    }
  });

  return(
    <main className="mb-10">
      <LoadingBar color='#2B88ED' ref={ref} height='3px' />
      <Header/>
      <div className="main m-auto px-2.5 py-16 container">
          <h3 className="font-bold text-xl lg:text-4xl">Search Result Of : {ParamsValue}</h3>
          {NoResultFound ? (<h2 className="text-center mt-10">No Result Found!</h2>) : <Card items={GetData}/>}
      </div>
      {LoadMore && (
        <div className="text-center mt-8 md:hidden lg:hidden">
          <button style={{backgroundColor: '#2B88ED',padding: '8px 18px',borderRadius: '5px'}} onClick={HandleMobileLoadMore}>Load More</button>
        </div>
      )}
      {NoMoreData && (
        <><h2 className="text-center bold">No More Result!</h2></>
      )}
      {loading &&(
      <div className={`loading`}>
        <div className={`lds_spinner`}>
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
      )}
    </main>
  )
}



export default SearchComponent;